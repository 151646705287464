<template>
	<div class="detailContext">
		<Top />
		<img
			v-for="(item, index) in imageList"
			:src="item"
			:key="index"
			alt="Your Image"
		/>
		<video
			muted
			loop
			controls
			v-for="(item, index) in videoList"
			:key="index"
			class="videoWidth"
		>
			<source :src="item" type="video/mp4" />
		</video>
		<div class="bottomMenu">
			<div class="text" v-if="!isFirst" @click="changeCase(false)">
				{{$t("work.before")}}
			</div>
			<div class="text" v-else></div>
			<div class="top" @click="scrollToTop">
				<svg
					t="1692871332900"
					class="icon"
					viewBox="0 0 1024 1024"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					p-id="15388"
					width="50"
					height="50"
				>
					<path
						d="M933.236364 563.2c-6.981818 6.981818-16.290909 9.309091-25.6 9.309091s-18.618182-2.327273-25.6-9.309091L546.909091 228.072727V861.090909c0 18.618182-16.290909 34.909091-34.909091 34.909091s-34.909091-16.290909-34.909091-34.909091V232.727273v-4.654546L141.963636 563.2c-13.963636 13.963636-34.909091 13.963636-48.872727 0s-13.963636-34.909091 0-48.872727L453.818182 151.272727c30.254545-30.254545 83.781818-30.254545 114.036363 0L930.909091 514.327273c13.963636 13.963636 13.963636 34.909091 2.327273 48.872727z"
						fill="#2c2c2c"
						p-id="15389"
					></path>
				</svg>
			</div>
			<div class="text" v-if="!isLast" @click="changeCase(true)">
				{{$t("work.next")}}
			</div>
			<div class="text" v-else></div>
		</div>
	</div>
</template>

<script>
import Top from '../components/Top.vue'
import { api } from '../server/axios'
// import 'intersection-observer'
export default {
	components: {
		Top
	},

	data() {
		return {
			id: undefined,
			imageList: [],
			videoList: [],
			isFirst: false,
			isLast: false
		}
	},

	methods: {
		scrollToTop() {
			window.scrollTo(0, 0)
		},
		beforeRouteUpdate(to, from, next) {
			this.init()
			// 在路由更新之前执行的操作
			// 可以在这里更新组件的数据或执行其他逻辑
			// setTimeout(function () {
			// 	window.location.reload()
			// }, 100)
			// 调用 next() 继续路由更新
			next()
		},

		//拆分图片数组和视频数组
		separateLinks(links) {
			const imageRegex = /\.(jpeg|jpg|gif|png)$/i // 图片链接的正则表达式
			const videoRegex = /\.(mp4|avi|mov|wmv)$/i // 视频链接的正则表达式
			const result = {
				images: [],
				videos: []
			}
			links.forEach((link) => {
				// console.log('我是link', link)
				if (imageRegex.test(link)) {
					// console.log('我被分到了image')
					result.images.push(link)
				} else if (videoRegex.test(link)) {
					// console.log('我被分到了video')
					result.videos.push(link)
				}
			})

			return result
		},
		async changeCase(isAdd) {
			// this.scrollToTop()
			console.log('changeCase', this.id)
			let id = this.id
			let changeId = await api.getId(id, isAdd)
			console.log(changeId)
			this.id = changeId
			this.$router.replace({
				path: `/workdetail/${changeId}`
			})
			// this.$router.scrollBehavior

			// setTimeout(function () {
			// 	window.location.reload()
			// }, 100)
			this.$router.go(0)
			// window.scrollTo(0, 0)
		},
		async init() {
			let id = this.id || this.$route.params.id
			this.id = id
			const isFirst = await api.isFirst(this.id || this.$route.params.id)
			const isLast = await api.isLast(this.id || this.$route.params.id)
			this.isFirst = isFirst
			this.isLast = isLast
			let datailList = await api.getworkDetail(id)
			let result = this.separateLinks(datailList[0])
			this.imageList = result.images
			this.videoList = result.videos
		}
	},
	async mounted() {
		await this.init()
		// console.log('执行mounted')
		setTimeout(() => {
			this.scrollToTop()
		}, 100)
	}
}
</script>

<style scoped>
.detailContext {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
img {
	width: 95%;
	margin-bottom: 3%;
}
.videoWidth {
	width: 95%;
	margin-bottom: 3%;
}
.top {
	cursor: pointer;
}
.text:hover {
	text-decoration-line: underline;
	cursor: pointer;
}
.bottomMenu {
	width: 95%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.875rem;
	font-size: 1.875rem;
}
@media (max-width: 768px) {
	.bottomMenu {
		font-size: 1.25rem;
	}
	.top {
		width: 1.875rem;
		height: 1.875rem;
		display: flex;
	}
	.text {
		margin-top: 0.625rem;
	}
}
</style>
