import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n/i18n';

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/font/font.css'

Vue.use(BootstrapVue)
import router from './router'

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	beforeCreate() {
		Vue.prototype.$bus = this
	},
	render: (h) => h(App)
}).$mount('#app')
