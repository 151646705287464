export default {
    connect:{
        name:"宗介龙野。",
        contact:"商务合作",
        redBook:"小红书",
        RQ11:"扫描二维码关注",
        RQ12:"公司官方公众号",
        RQ21:"扫描二维码添加",
        RQ22:"公司主理人微信",
        RQ31:"扫描二维码使用",
        RQ32:"塔咦尤AI机器人",
    },
    work:{
        before:"上一个案例",
        next:"下一个案例"
    },
    footer:{
        name:"宗介龙野。",
        contact:"商务合作",
        redBook:"小红书"
    }
}