<template>
	<div class="detailContext">
		<Top />

		<img
			v-for="(item, index) in imageList"
			:src="item"
			:key="index"
			alt="Your Image"
			v-float-animation
		/>
		<video
			muted
			loop
			controls
			v-for="(item, index) in videoList"
			:key="index"
			class="videoWidth"
		>
			<source :src="item" type="video/mp4" />
		</video>
		<Footer></Footer>
	</div>
</template>

<script>
import Top from '../components/Top.vue'
import Footer from '@/components/Foot.vue'
import { api } from '../server/axios'
export default {
	components: {
		Footer,Top
	},
	data() {
		return {
			id: undefined,
			imageList: [],
			videoList: []
		}
	},
	methods: {
		//拆分图片数组和视频数组
		separateLinks(links) {
			const imageRegex = /\.(jpeg|jpg|gif|png)$/i // 图片链接的正则表达式
			const videoRegex = /\.(mp4|avi|mov|wmv)$/i // 视频链接的正则表达式
			const result = {
				images: [],
				videos: []
			}

			links.forEach((link) => {
				// console.log('我是link', link)
				if (imageRegex.test(link)) {
					// console.log('我被分到了image')
					result.images.push(link)
				} else if (videoRegex.test(link)) {
					// console.log('我被分到了video')
					result.videos.push(link)
				}
			})

			return result
		}
	},
	async mounted() {
		// console.log(this.$route.query.id)
		let data = await api.getAboutData()
		let result = this.separateLinks(data.split('|'))
		this.imageList = result.images
		this.videoList = result.videos
	}
}
</script>

<style scoped>
.detailContext {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
img {
	width: 95%;
	margin-bottom: 3%;
}
.videoWidth {
	width: 95%;
	margin-bottom: 3%;
}
.top {
	cursor: pointer;
}
.text:hover {
	text-decoration-line: underline;
	cursor: pointer;
}
.bottomMenu {
	width: 95%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.875rem;
	font-size: 1.875rem;
}
@media (max-width: 768px) {
	.bottomMenu {
		font-size: 1.25rem;
	}
	.top {
		width: 1.875rem;
		height: 1.875rem;
		display: flex;
	}
}
.float-animation {
	transition: transform 0.5s ease;
}
.float-animation {
	transition: transform 0.5s ease;
}

.float-animation.float-enter {
	transform: translateY(100%);
}

.float-animation.float-enter-active {
	transform: translateY(0);
}
</style>
