export default {
    connect:{
        name:"Zong Jielongye.",
        contact:"Cooperation",
        redBook:"LittleRedBook",
        RQ11:"Scan the QR code to follow",
        RQ12:"Official company WeChat account",
        RQ21:"Scan the QR code to add",
        RQ22:"Company Principal’s WeChat",
        RQ31:"Scan the QR code to use",
        RQ32:"Tayiyou AI Robot",
    },
    work:{
        before:"Previous case",
        next:"Next case"
    },
    footer:{
        name:"Zong Jielongye.",
        contact:"Cooperation",
        redBook:"LittleRedBook"
    }
}