<template>
	<div class="fullscreen-video">
		<button class="home-button">
			<router-link to="/home">
				<img src="../assets/home page.png" class="home" alt="" />
			</router-link>
		</button>
		<button @click="toggleMenu" class="menu-button">
			<img src="../assets/menu.png" alt="" class="menuIcon" />
			<!-- <i>
				<svg
					t="1706174057968"
					class="icon"
					viewBox="0 0 1249 1024"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					p-id="6746"
					width="30"
					height="30"
				>
					<path
						d="M1186.816 124.928H62.464C27.648 124.928 0 97.28 0 62.464S27.648 0 62.464 0h1124.352c33.792 0 62.464 27.648 62.464 62.464s-28.672 62.464-62.464 62.464z m0 449.536H62.464C27.648 574.464 0 546.816 0 512s27.648-62.464 62.464-62.464h1124.352c33.792 0 62.464 27.648 62.464 62.464s-28.672 62.464-62.464 62.464z m0 449.536H62.464C27.648 1024 0 996.352 0 961.536s27.648-62.464 62.464-62.464h1124.352c33.792 0 62.464 27.648 62.464 62.464S1220.608 1024 1186.816 1024z"
						p-id="6747"
						fill="#ffffff"
					></path>
				</svg>
			</i> -->
		</button>
		<div v-if="showMenu" class="fullscreen-menu">
			<!-- Fullscreen menu content -->
			<div class="menu">
				<p class="menuItem" @click="goHome">x</p>
				<p
					class="menuItem"
					@mouseover="showText('work')"
					@mouseleave="hideText('work')"
				>
					<router-link to="/work">
						<span v-if="!isTextVisible.work" @click="toggleMenu">Work</span>
						<span
							v-if="isTextVisible.work"
							class="underline"
							@click="toggleMenu"
							>项目案例</span
						>
					</router-link>
				</p>
				<p
					class="menuItem"
					@mouseover="showText('about')"
					@mouseleave="hideText('about')"
				>
					<router-link to="/about" @click="toggleMenu">
						<span v-if="!isTextVisible.about">About</span>
						<span
							v-if="isTextVisible.about"
							class="underline"
							@click="toggleMenu"
							>关于我们</span
						>
					</router-link>
				</p>
				<p
					class="menuItem"
					@mouseover="showText('connect')"
					@mouseleave="hideText('connect')"
				>
					<router-link to="/connect">
						<span v-if="!isTextVisible.connect">Contact</span>
						<span
							v-if="isTextVisible.connect"
							class="underline"
							@click="toggleMenu"
							>联系我们</span
						>
					</router-link>
				</p>
			</div>
			<!-- <div class="cancel" @click="toggleMenu">x</div> -->
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showMenu: false,
			isTextVisible: {
				work: false,
				about: false,
				connect: false
			}
		}
	},
	methods: {
		toggleMenu() {
			this.showMenu = !this.showMenu
		},
		goHome() {
			this.$router.push({ path: '/home' })
			this.toggleMenu()
		},

		showText(index) {
			this.$set(this.isTextVisible, index, true)
		},
		hideText(index) {
			this.$set(this.isTextVisible, index, false)
		}
	}
}
</script>

<style scoped>
a {
	text-decoration: none;
	color: #000;
}
.home {
	width: 6vw;
}
.menuIcon {
	width: 1vw;
}

.logoArea {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 21.875rem;
	height: auto;
	text-align: center;
	color: #fff;
	font-size: 1.5rem;
}
.logo {
	width: 21.875rem;
}
.goto {
	width: 6.25rem;
}

.home-button {
	position: fixed;
	width: 1.25rem;
	height: 1.25rem;
	top: 1.25rem;
	left: 1.25rem;
	z-index: 1;
	background: none;
	border: none;
	cursor: pointer;
}

.home-button .home {
	font-family: 'PingFang SC-Medium';
	font-size: 0.75rem;
}

.menu-button {
	position: fixed;
	top: 1.25rem;
	right: 1.25rem;
	z-index: 1;
	background: none;
	border: none;
	color: white;
	font-size: 1.5rem;

	cursor: pointer;
}

.fullscreen-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(255, 255, 255, 1);
	z-index: 9999;
	/* Add menu styles here */
}
.menu {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	width: 80%;

	margin: auto;
	left: 0;
	right: 500px;
	top: 0;
	bottom: 0;
	height: 12.5rem;
	/* justify-content: center; */
}
.menuItem {
	color: #000;
	font-size: 1.5rem;
	line-height: 1.4em;
	font-weight: 500;
	font-family: 'PingFang SC-Medium';
	width: 100%;
	text-align: right;
	user-select: none;
	cursor: pointer;
}
.menuItem a span {
	font-family: 'PingFang SC-Medium';
	font-weight: 500;
	font-size: 1.5rem;
}
.underline {
	text-decoration-line: underline;
}
.cancel {
	position: relative;
	float: right;
	right: 3.125rem;
	color: #008ceb;
	font-size: 5.5rem;
	line-height: 1.4em;
	font-weight: bold;
	user-select: none;
	cursor: pointer;
}
@media (max-width: 768px) {
	.home {
		width: auto;
	}
	.menuIcon {
		width: auto;
	}
}
</style>
