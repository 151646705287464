<template>
	<div id="app">
		<!-- <top></top> -->
		<!-- <Home></Home> -->
		<!-- <Work></Work> -->
		<!-- <Footer></Footer> -->
		<!-- <WorkDetail></WorkDetail> -->
		<!-- <router-link to="/">home</router-link>
		<router-link to="/home">home</router-link>

		<router-link to="/workdetail">workdetail</router-link>
		<router-link to="/connect">Contact</router-link> -->
		<router-view></router-view>
		<!-- <Connect></Connect> -->
	</div>
</template>

<script>
// import Top from './components/Top.vue'
// import Home from './view/Home.vue'
// import Work from './view/work.vue'
// import Footer from './components/footer.vue'
// import WorkDetail from './view/WorkDetail.vue'

// import Connect from './view/Connect.vue'

export default {
	name: 'App',
	components: {
		// Top
		// Home
		// Work,
		// WorkDetail
		// Footer
		// Connect
	}
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
}
@font-face {
	font-family: 'PingFang SC-Medium';
	src: url('/asset/font/PingFangMedium.otf') format('truetype');
}

</style>
