<template>
	<div class="image-gallery">
		<Top />
		<div class="custom-row" no-gutters="false">
			<b-row>
				<b-col
					cols="12"
					sm="4"
					v-for="image in images"
					:key="image.id"
					class="image-container"
				>
					<img @click="goToDetail(image.id)" :src="image.showPic" class="img" />
					<img
						@click="goToDetail(image.id)"
						:src="image.hoverPic"
						class="hover-img"
					/> 
				</b-col>
			</b-row>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Top from '@/components/Top.vue'
import Footer from '@/components/Foot.vue'
import { api } from '../server/axios'
export default {
	components: {
		Top,
		Footer
	},
	data() {
		return {
			images: []
		}
	},
	methods: {
		goToDetail(id) {
			this.$router.push({ path: `/workdetail/${id}` })
		}
	},
	created() {},
	async mounted() {
		api.getAboutData()
		const images = await api.getWorkList()
		this.images = images
	}
}
</script>

<style scoped>
body {
	width: 100%;
}

.image-container {
	position: relative;
}
.img {
	transition: opacity 0.3s ease-in-out;
}
.hover-img {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}
.hover-img {
	opacity: 0;
}

.image-container:hover .img {
	opacity: 0;
}

.image-container:hover .hover-img {
	opacity: 1;
}
.cover {
	position: relative;
}
.hover {
	position: relative;
	z-index: -100;
}

img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.row {
	--bs-gutter-x: 0 !important;
	padding-left: 0 !important;
}
</style>
