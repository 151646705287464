<template>
  <div class="main">
    <div class="right_foot">
		<img class="icon" src="../assets/zhanku.png" alt="" />
		<img class="icon" src="../assets/douyin.png" alt="" />
		<img class="icon" src="../assets/weixin.png" alt="" />
		<img class="icon" src="../assets/xiaohongshu.png" alt="" />
	</div>
    
    <div class="left_foot">
        <div>Chengdu {{$t("footer.name")}}</div>
        <div>{{$t("footer.contact")}}: 133 0806 8891</div>
        <div>{{$t("footer.redBook")}}: 4173814842</div>
        <div class="beian" @click="gotoBeian" >蜀ICP备2024052368号-1</div>
    </div>

  </div>
</template>

<script>
export default {
    methods: {
        gotoBeian() {
            window.open('https://beian.miit.gov.cn');
        }
    }
}
</script>

<style scoped>

img{
    filter: invert(100%);
    width: 1.1vw;
    height: 1.1vw;
    margin-left: 1vw;
}
.beian{
    cursor: pointer;
}
.main{
    display: flex;
    width: 100%;
    height: 3vw;
    line-height: 3vw;
    justify-content: space-around;
    align-content: center;
    background: #000;
    color: #fff;
    font-size: 1vw;
	font-family: 'PingFang SC-Medium';
    padding-left: 1vw;
    padding-right: 2vw;
}
.left_foot{
    order: 2; 
    margin-left: auto;
}
.right_foot {
  order: 1;
  margin-right: auto;
}

.left_foot div{
    display: inline;
    justify-content: center;
    align-content: center;
    margin-left: 1.2vw;
}


@media (max-width: 800px) {
    img{
    filter: invert(100%);
    width: 8vw;
    height: 8vw;
    margin-left: 5vw;
    }

    .main{
        display: block;
        width: 100vw;
        height: 50vw;
        padding: 5vw;
        line-height: 3vw;
        justify-content: flex-start;
        align-content: center;
        background: #000;
        color: #fff;
        font-size: 5vw;
        font-family: 'PingFang SC-Medium';
    }
    .left_foot{
        display: flex;
        flex-direction: column;
        margin-top: 4vw;
        margin-left: 4vw;
    }
    .right_foot {
        margin-top: 4vw;
    }

    .left_foot div{
        margin-top: 4vw;
    }
}
</style>