<template>
	<div class="connect" ref="connectRef">
		<div class="top" v-if="showMenu">
			<Top ref="topComponent" />
		</div>

		<div class="connectBox">
			<div class="leftText">
				<div class="oneCol">
					chengdu<br />
					{{$t("connect.name")}}<br />
					{{$t("connect.contact")}}:<br />133 0806 8891<br />{{$t("connect.redBook")}}:<br />Sosukeryuuno
				</div>
				<div class="cancel" @click="back">
					<img src="../assets/cacel.png" alt="" />
				</div>
				<div class="rightContext">
					<div class="qrcodeBox">
						<div class="qrItem">
							<img src="../assets/QR1.png" alt="" />
							<p>{{$t("connect.RQ11")}}</p>
							<p>『 {{$t("connect.RQ12")}} 』</p>
						</div>
						<div class="qrItem">
							<img src="../assets/QR2.png" alt="" />
							<p>{{$t("connect.RQ21")}}</p>
							<p>『 {{$t("connect.RQ22")}} 』</p>
						</div>
						<div class="qrItem">
							<img src="../assets/QR3.png" alt="" />
							<p>{{$t("connect.RQ31")}}</p>
							<p>『 {{$t("connect.RQ32")}} 』</p>
						</div>
					</div>
				</div>
				<div class="bottomIcon">
					<img class="icon" src="../assets/zhanku.png" alt="" />
					<img class="icon" src="../assets/douyin.png" alt="" />
					<img class="icon" src="../assets/weixin.png" alt="" />
					<img class="icon" src="../assets/xiaohongshu.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Top from '@/components/Top.vue'
export default {
	components: {
		Top
	},
	data() {
		return {
			showMenu: false
		}
	},
	methods: {
		back() {
			// this.$router.go(-1)
			console.log('点击了')
			this.showMenu = true
			console.log(this.$refs.topComponent)
			setTimeout(() => {
				this.$refs.topComponent.toggleMenu()
				this.$nextTick(() => {
					this.$refs.connectRef.addEventListener('click', this.handleClick)
				})
			}, 0)
		},
		handleClick() {
			this.showMenu = !this.showMenu
			this.$refs.connectRef.removeEventListener('click', this.handleClick)
		}
	},
	mounted() {
		this.showMenu = false
	}
}
</script>

<style scoped>
.connect {
	display: flex;
	margin-top: 3.125rem;
	flex-wrap: wrap;
	font-family: PingFangSC-Heavy, sans-serif;
}
.connectBox {
	flex-basis: 100%;
	height: 100vh;
}
.cancel {
	position: absolute;
	top: -5vw;
	right: 3vw;
	cursor: pointer;
}
.leftText {
	position: relative;
	font-weight: 500;
	font-family: 'PingFang SC-Medium';
	font-size: 5.8vw;
	line-height: 5.8vw;
	flex-wrap: wrap;
	margin: 3vw;
}
.rightContext {
	position: absolute;
	top: 0;
	right: 0;
}
.qrcodeBox {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
}
.qrItem {
	text-align: center;
	width: 10vw;
	height: 10vw;
	margin-right: 2.5vw;
}
.qrItem p {
	font-size: 1vw;
	font-family: 'PingFang SC-Medium';
}
.qrItem img {
	width: 100%;
	height: 100%;
}
.qrItem p:nth-child(2) {
	margin-top: -2vw;
}
.qrItem p:nth-child(3) {
	margin-top: -5vw;
}
.bottomIcon {
	position: absolute;
	bottom: 0%;
	right: 0%;
	width: 20%;
	display: flex;
	justify-content: space-evenly;
}
.bottomIcon img {
	width: 2vw;
}
@media screen and (min-width: 788px) {
	.qrItem{
		display: flex;
		flex-direction: column;
		position: relative;
		
	}
	.qrItem p:nth-child(2) {
		margin-top: 1vw;
		line-height:1vw;
	}
	.qrItem p:nth-child(3) {
		margin-top: 0.1vw;
		line-height:1vw;
	}
}
@media (max-width: 768px) {
	.connect {
		margin-top: 0;
	}
	.connectBox {
		display: flex;
		margin-top: 5vw;
		flex-wrap: wrap;
		height: auto;
	}
	.leftText {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		height: 100%;
		font-weight: 900;
		text-align: center;
		padding-left: 0;
		padding-bottom: 5vw;
	}
	.oneCol {
		font-size: 9vw;
		line-height: 9vw;
	}
	.rightContext {
		position: relative;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 66%;
	}

	.qrcodeBox {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.qrItem {
		width: 80%;
		height: 55vw;
		text-align: center;
		margin-right: 0;
	}
	.qrItem img {
		width: 40vw;
		height: 40vw;
	}
	.qrItem p {
		font-size: 4vw;
		line-height: 4vw;
	}
	.qrItem p:nth-child(2) {
		margin-top: 0vw;
	}
	.qrItem p:nth-child(3) {
		margin-top: -3vw;
	}
	.bottomIcon {
		width: 100%;
		position: static;
	}
	.bottomIcon img {
		width: 8vw;
		height: 8vw;
	}
}
</style>
