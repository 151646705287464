<template>
	<div>
		<router-link to="/work">
			<video v-if="videoUrl!=''" autoplay muted loop>
			<source :src="videoUrl" type="video/mp4" />
				您的浏览器不支持HTML5 video标签。
			</video>
		</router-link>
		
		<!-- <div class="logoArea">
			<img src="../assets/logo.svg" class="logo" />
			<router-link to="/work">
				<div class="goToWork">Project Case Analysis→</div>
			</router-link>
		</div> -->
		<Top />
		<Footer></Footer>
		
	</div>
</template>

<script>
import Top from '@/components/Top.vue'
import Footer from '@/components/Foot.vue'
import { api } from '../server/axios'
export default {
	components: {
		Top,Footer
	},
	data() {
		return {
			videoUrl:''
		}
	},
	methods: {
	},
	async mounted() {
		this.videoUrl= await api.getVideoData()
	}
}
</script>

<style scoped>
*{
    box-sizing: border-box;
	margin:0;
	padding: 0;
}
a {
	text-decoration: none;
	color: #000;
}
/* .fullscreen-video {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
} */

video {
	/* position: absolute;
	top: 0;
	left: 0;*/
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.logoArea {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 25vw;
	height: auto;
	text-align: center;
	color: #fff;
	font-size: 1.5rem;
}
.logo {
	width: 100%;
}

.goToWork {
	font-size: 0.7vw;
	margin-top: 1vw;
	cursor: pointer;
	color: #fff;
	font-family: 'PingFang SC-Medium';
}
.goToWork:hover {
	text-decoration-line: underline;
}
@media (max-width: 768px) {
	video {
	height: 100%;
	height: 100vh;
	object-fit: cover;
}
	.home {
		width: auto;
	}
	.menuIcon {
		width: auto;
	}
	.logoArea {
		width: 50vw !important;
	}
	.goToWork {
		font-size: 2vw;
	}
}
</style>
