import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs/index'
Vue.use(VueI18n)

const navLang = navigator.language || navigator.userLanguage;
const i18n = new VueI18n({
    locale: navLang || 'zh-CN',//"en-US"
    messages,
})
export default i18n
