// 导入Vue和Vue Router
import Vue from 'vue'
import VueRouter from 'vue-router'

// 导入你的组件
import Home from '../view/Home.vue'
import Work from '../view/Work.vue'
import WorkDetail from '../view/WorkDetail.vue'
import Connect from '../view/Connect.vue'
import About from '../view/About.vue'

// 使用Vue Router插件
Vue.use(VueRouter)

// 定义路由配置
const routes = [
	{ path: '/', component: Home },
	{ path: '/home', name: 'Home', component: Home },
	{ path: '/workdetail/:id', name: 'WorkDetail', component: WorkDetail },
	{ path: '/work', name: 'Work', component: Work },
	{ path: '/about', name: 'About', component: About },
	{ path: '/connect', name: 'Connect', component: Connect }
]

// 创建路由实例
const router = new VueRouter({
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

// 导出路由实例
export default router
