import axios from 'axios'
const baseURL = 'https://taiyo.munesukehhh.com/api'

const instance = axios.create({
	baseURL: baseURL,
	timeout: 5000 // 设置请求超时时间
})

instance.interceptors.response.use(
	(response) => {
		return response.data.data
	},
	async (error) => {
		return Promise.reject(error)
	}
)

let workDetailStore = []
export const api = {
	//获取案例列表及存储详情页数据
	async getWorkList() {
		let rowData = await instance.get(`/taiyo/official/open/web/project`)
		let workDetailArray = rowData.map((item) => {
			// delete item.hoverPic
			// delete item.showPic
			item.hover = false
			item.attachment = item.attachment.split('|')
			return item
		})
		// console.log(workDetailArray)
		workDetailStore = workDetailArray
		return workDetailArray
	},
	//处理详情页数据
	async getworkDetail(id) {
		let result = []
		workDetailStore.length === 0 ? await this.getWorkList() : ''
		workDetailStore.map((item) => {
			if (item.id == id) {
				result.push(item.attachment)
			}
		})
		return result
	},

	//查询自己id所在位置 判断头尾的上一个/下一个显示
	async getSelfIndex(id) {
		workDetailStore.length === 0 ? await this.getWorkList() : ''
		let idIndex
		workDetailStore.forEach((item, index) => {
			if (item.id == id) {
				idIndex = index
			}
		})
		return idIndex
	},

	async isFirst(id) {
		const index = await this.getSelfIndex(id)
		return index == 0
	},
	async isLast(id) {
		const index = await this.getSelfIndex(id)
		return index == workDetailStore.length - 1
	},
	//获取上下页id
	async getId(id, isAdd = true) {
		workDetailStore.length === 0 ? await this.getWorkList() : ''
		let idIndex = await this.getSelfIndex(id)
		console.log('idIndex', idIndex)
		isAdd ? idIndex++ : idIndex--
		if (idIndex < 0 || idIndex > workDetailStore.length - 1) {
			return null
		} else {
			console.log('workDetailStore', workDetailStore)

			return workDetailStore[idIndex].id
		}
	},
	//获取关于我们的数据
	async getAboutData() {
		let data = await instance.get(`/taiyo/official/open/web/about `)
		// let data
		return data.attachment
	},
	//获取首页视频
	async getVideoData() {
		let data = await instance.get(`/taiyo/official/open/web/video`)
		
		return data.attachment
	}
}
